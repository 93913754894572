<template>
  <div id="error">
    <img src="@/assets/image/stateHint/icon_error.png" alt="">
    <p>糟糕，页面走丢了～</p>
    <a-button @click="onReturnHome">返回首页</a-button>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    onReturnHome () {
      this.$router.replace('/')
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
#error{
  padding-top: 200px;
}
img{
  width: 340px;
  display: block;
  margin: 0 auto;
}
p{
  text-align: center;
  font-size: 16px;
  color: #666;
  font-weight: bold;
  padding: 30px 0 30px 0;
}
button{
  margin: 0 auto;
  display: block;
  margin-bottom: 100px;
}
</style>
